.homeSlider .swiper-button-next:after,
.homeSlider .swiper-button-prev:after {
  color: rgba(255, 255, 255, 0.5);
}

.homeSlider .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 25px;
}

.homeSlider .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background-color: transparent;
  border: 1.5px solid #fff;
}

.homeSlider .swiper-pagination-bullet-active {
  background-color: #fff;
}
