.global-lynx {
  position: relative;
  display: inline-block;
  width: max-content;
  span {
    font-family: 'Global Lynx', sans-serif;
    position: absolute;
    color: transparent;
    font-weight: 700;
    letter-spacing: -0.03em;
    &::selection {
      color: white;
    }
  }
  &--logo {
    left: 0;
    bottom: -10%;
    color: inherit;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
  }
}
