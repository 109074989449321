/* Basic Table */
.table {
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  table {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    border-collapse: collapse;
    border-spacing: 10px 0px;
    thead,
    tbody {
      text-align: left;
    }
    thead {
      tr {
        background: var(--blue-darkest);
        th {
          max-width: 200px;
          vertical-align: top;
          &:not(:first-child) {
            padding-left: 10px;
          }
          &:not(:last-child) {
            padding-right: 10px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:not(:first-child) {
            padding-left: 10px;
          }
          &:not(:last-child) {
            padding-right: 10px;
          }
          max-width: 200px;
        }
        &:nth-child(even) {
          background-color: rgb(236, 236, 236);
        }
      }
    }
  }
}
